import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  CidadeParams,
  FornecedorProviderProps,
  SelectProps,
  CondicaoProps,
  MasterDetailProps,
  FornecedorContextData,
  TribSimplesProps,
  CondicaoDataProps,
} from '../protocols';
import { loadingContext } from '~/context/loading';
import { SelectType } from '~/components/NovosInputs/InputCidade/protocols';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { condicao, schema } from '../validations';
import { addUpdate, deleteItens, insertOrUpdate } from '~/utils/masterDetail';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import moment from 'moment';
import { toast } from 'react-toastify';
import { CondicaoData, Default, Fretes } from '../data';
import api from '~/services/api';
import { ICEP, ICNPJ } from '~/components/Pessoa/types';
import axios from 'axios';
import {
  formatCurrencyAsText,
  transformAsCurrency,
  validaCPF,
  validarCNPJ,
} from '~/utils/functions';
import { nanoid } from 'nanoid';
import { GridRowParams } from '@material-ui/data-grid';
import { validar } from '~/utils/ie';
import { RetencaoProps } from '../protocols/Retencao';
import { createDateWithoutTimezone } from '~/utils/createDateWithoutTimezone ';

const MySwal = withReactContent(Swal);

export const FornecedorContext = createContext({} as FornecedorContextData);

export const FornecedorProvider: React.FC<FornecedorProviderProps> = ({
  children,
}) => {
  const { setLoading } = useContext(loadingContext);

  const [loader, setLoader] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  const [tab, setTab] = useState<any>('geral');
  const [inputSite, setinputSite] = useState<any>('');
  const [init, setInit] = useState(false);
  const [initUf, setInitUf] = useState(false);
  const [cidade, setCidade] = useState<CidadeParams>();
  const [cidadeSelected, setCidadeSelected] = useState<SelectType>();
  const [clearCampoCidade, setClearCampoCidade] = useState(false);
  const [nameFornecedor, setNameFornecedor] = useState('');

  const [fornecedor, setFornecedor] = useState<number | undefined>();
  const [tipoPessoa, setTipoPessoa] = useState<number>(1);
  const [loadingIcon, setLoadingIcon] = useState<string>();
  const [flagInativo, setFlagInativo] = useState(false);

  const nascimentoRef = useRef<any>();
  const inputCidadeRef = useRef<HTMLDivElement | null>(null);

  // Características
  const [flg_fabricante, setFlg_fabricante] = useState(false);
  const [flg_exportador, setFlg_exportador] = useState(false);
  const [flg_regime_st, setFlg_regime_st] = useState(false);
  const [flg_regime_mei, setFlg_regime_mei] = useState(false);
  const [flg_substituto_trib, setFlg_substituto_trib] = useState(false);

  const [flg_optante_simples, setFlg_optante_simples] = useState(false);

  const [tributacoes, setTributacoes] = useState<SelectProps[]>([]);
  const [flg_calc_frete_val_bruto, setFlg_calc_frete_val_bruto] =
    useState(false);

  const [flg_calc_indeniz_val_bruto, setFlg_calc_indeniz_val_bruto] =
    useState(false);

  const [flg_calc_desp_ac_val_bruto, setFlg_calc_desp_ac_val_bruto] =
    useState(false);

  const [flg_calc_bonif_val_bruto, setFlg_calc_bonif_val_bruto] =
    useState(false);

  const [flg_calc_acresc_val_bruto, setFlg_calc_acresc_val_bruto] =
    useState(false);

  const [flg_calc_ipi_val_bruto, setFlg_calc_ipi_val_bruto] = useState(false);

  const [flg_rateia_icms_custo, setFlg_rateia_icms_custo] = useState(false);

  const [flg_nao_desc_bc_st, setFlg_nao_desc_bc_st] = useState(false);

  const [flg_nao_transf_icms, setFlg_nao_transf_icms] = useState(false);

  const [flg_nao_transf_pis_cofins, setFlg_nao_transf_pis_cofins] =
    useState(false);

  const [flg_nao_cred_presumido, setFlg_nao_cred_presumido] = useState(false);

  const [tipo_produtor, setTipo_produtor] = useState<number>(0);

  const [flg_produtor_rural, setFlg_produtor_rural] = useState(false);
  const [disabledSearch, setDisabledSearch] = useState(false);

  const [flg_suframa, setFlg_suframa] = useState(false);
  const [flg_pauta, setFlg_pauta] = useState(false);
  const [flg_aceita_devol_merc, setFlg_aceita_devol_merc] = useState(false);
  const [flg_contabilista, setFlg_contabilista] = useState(false);
  // Condicao
  const [finalizadoras, setFinalizadoras] = useState<SelectProps[]>([]);
  const [condicoes, setCondicoes] = useState<CondicaoProps[]>([]);
  const [diasCondicao, setDiasCondicao] = useState<number>();
  const [condicoesOptions, setCondicoesOptions] = useState<SelectProps[]>([]);
  const [condicaoAEditar, setCondicaoAEditar] = useState<any>(undefined);
  const [initCondicao, setInitCondicao] = useState(false);

  // Compra
  const [flg_beneficiario, setFlg_beneficiario] = useState(false);
  const [isUpdateRetencoes, setIsUpdateRetencoes] = useState({
    uuid: undefined,
    isUpdate: false,
  });
  const [retencoes, setRetencoes] = useState([]);
  const [retencoesOptions, setRetencoesOptions] = useState<SelectProps[]>([]);
  const [retencoesRows, setRetencoesRows] = useState<RetencaoProps[]>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const masterDetailDefault = [
    {
      obj_name: 'condicao',
      pk_fields: ['cod_seq_pessoa_condicao'],
      itens: {
        insert: [],
        update: [],
        delete: [],
      },
    },
    {
      obj_name: 'retencao',
      pk_fields: ['cod_seq_forn_ret'],
      itens: {
        insert: [],
        update: [],
        delete: [],
      },
    },
  ];

  const [masterDetail, setMasterDetail] =
    useState<MasterDetailProps[]>(masterDetailDefault);

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    setError,
    clearErrors,
    watch,
    formState,
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  const { isDirty, errors } = formState;

  /**
   * useForm para controle do formulário Condicao
   */
  const {
    register: condicaoRegister,
    handleSubmit: handleSubmitCondicao,
    control: condicaoControl,
    reset: resetCondicao,
    setValue: setValueCondicao,
    formState: formStateCondicao,
  } = useForm({
    resolver: yupResolver(condicao),
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    if (inputCidadeRef.current) {
      const inputCidade: HTMLInputElement | null =
        inputCidadeRef.current.querySelector('[name="cidades"]');

      if (inputCidade && cidadeSelected) {
        const { label } = cidadeSelected;
        const { value } = inputCidade;

        if (value !== label) inputCidade.value = label;
      }
    }
  }, [cidadeSelected]);

  useEffect(() => {
    (async () => {
      if (errors) {
        if (
          !errors.bairro &&
          !errors.cep &&
          !errors.cidade &&
          !errors.estados &&
          !errors.logradouro &&
          !errors.name &&
          !errors.num_cpf &&
          !errors.num_cnpj &&
          !errors.num_ie &&
          !errors.numero &&
          !errors.sexo
        ) {
          if (errors.produtor_rural_num_cpf) setTab('caracteristicas');
          setInit(true);
        } else {
          setTab('geral');
          setInitUf(true);
          setInit(true);
        }
      }
    })();
  }, [errors]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/finalizadora');
      if (data.success) {
        if (data.message !== 'Nenhum registro encontrado.') {
          const options = data.data.map((ent: any) => ({
            label: `${ent.des_finalizadora}`,
            value: ent.cod_finalizadora,
          }));

          setFinalizadoras(options);
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/retencao-contabil');
      if (data.success) {
        if (data.message !== 'Nenhum registro encontrado.') {
          const options = data.data
            .map((ent: any) => ({
              label: `${ent.descricao}`,
              value: ent.cod_retencao,
            }))
            .sort((a: any, b: any) => a.value - b.value);
          setRetencoesOptions(options);
          setRetencoes(data.data);
        }
      }
    })();
  }, []);
  /**
   * Listagem de ICMS
   */
  useEffect(() => {
    (async () => {
      const { data } = await api.get('/tribsimples');

      if (data.success) {
        if (data.message !== 'Nenhum registro encontrado.') {
          const options = data.data.map((trib: TribSimplesProps) => ({
            label: trib.des_tributacao,
            value: trib.cod_tributacao,
          }));
          setTributacoes(options);
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/condicao');
      if (data.success && data.message !== 'Nenhum registro encontrado.') {
        const formatedOptions = data.data.map((cond: CondicaoDataProps) => ({
          label: `${cond.des_condicao} - ${cond.des_definicao}`,
          value: cond.cod_condicao,
        }));
        setCondicoesOptions(formatedOptions);
      }
    })();
  }, []);

  useEffect(() => {
    if (formStateCondicao.errors.condicaoFinalizadora) {
      setInitCondicao(true);
    }
  }, [formStateCondicao.errors]);

  useEffect(() => {
    if (formStateCondicao.errors.condicaoFinalizadora) {
      setInitCondicao(true);
    }
  }, [formStateCondicao.errors]);

  const resetValues = useCallback(
    (changePeople: any) => {
      setInit(false);
      if (changePeople) {
        setTipoPessoa(0);
        setValue('tipoPessoa', 0);
      } else {
        setTipoPessoa(1);
        setValue('tipoPessoa', 1);
      }
      setValue('num_cpf', '');
      setValue('num_cnpj', '');
      setValue('num_ie', '');
      setValue('sexo', '');
      setValue('name', '');
      setValue('apelido', '');
      setValue('nascimentoAbertura', null);
      setValue('telefone', '');
      setValue('celular', '');
      setValue('contato', '');
      setValue('email', '');
      setValue('site', '');
      setValue('cep', '');
      setValue('logradouro', '');
      setValue('numero', '');
      setValue('bairro', '');
      setValue('complemento', '');
      setValue('cidades', '');
      setValue('estados', '');
      setValue('flg_fabricante', false);
      setValue('flg_exportador', false);
      setValue('flg_regime_st', false);
      setValue('flg_regime_mei', false);
      setValue('flg_substituto_trib', false);
      setValue('flg_optante_simples', false);
      setValue('cod_trib_simples', '');
      setValue('tipo_frete', { label: undefined, value: undefined });
      setValue('flg_calc_frete_val_bruto', false);
      setValue('flg_calc_indeniz_val_bruto', false);
      setValue('flg_calc_desp_ac_val_bruto', false);
      setValue('flg_calc_bonif_val_bruto', false);
      setValue('flg_calc_acresc_val_bruto', false);
      setValue('flg_calc_ipi_val_bruto', false);
      setValue('flg_rateia_icms_custo', false);
      setValue('flg_nao_desc_bc_st', false);
      setValue('flg_nao_transf_icms', false);
      setValue('flg_nao_transf_pis_cofins', false);
      setValue('flg_nao_cred_presumido', false);
      setValue('flg_produtor_rural', false);
      setValue('tipo_produtor', 0);
      setValue('produtor_rural_num_cpf', '');
      setValue('produtor_rural_num_cnpj', '');
      setValue('flg_contabilista', false);
      setValue('num_crc', '');
      setValue('num_seq_crc', '');
      setValue('dta_validade_crc', null);
      setValue('flg_suframa', false);
      setValue('flg_pauta', false);
      setValue('flg_aceita_devol_merc', false);
      setValue('val_minimo_troca', '');
      setValue('per_desconto', '');
      setValue('per_frete', '');
      setValue('per_acordo_fin', '');
      setValue('cod_produtor', undefined);
      setValue('flg_beneficiario', false);
    },
    [setValue],
  );

  const resetFormData = useCallback(
    async (changePeople?: boolean) => {
      const elm = document.getElementById('collapseThree-fornecedor');
      if (elm) {
        elm.removeAttribute('class');
        elm.classList.add('accordion-collapse', 'collapse');
      }
      setTab('geral');
      setClearCampoCidade(true);
      setCidade(undefined);
      reset();
      resetCondicao();
      setCondicoes([]);
      setRetencoesRows([]);
      setLoadingIcon('');
      resetValues(changePeople);
      setFlg_fabricante(false);
      setFlg_exportador(false);
      setFlg_regime_st(false);
      setFlg_regime_mei(false);
      setFlg_substituto_trib(false);
      setFlg_optante_simples(false);
      setFlg_calc_frete_val_bruto(false);
      setFlg_calc_indeniz_val_bruto(false);
      setFlg_calc_desp_ac_val_bruto(false);
      setFlg_calc_bonif_val_bruto(false);
      setFlg_calc_acresc_val_bruto(false);
      setFlg_calc_ipi_val_bruto(false);
      setFlg_rateia_icms_custo(false);
      setFlg_nao_desc_bc_st(false);
      setFlg_nao_transf_icms(false);
      setFlg_nao_transf_pis_cofins(false);
      setFlg_nao_cred_presumido(false);
      setFlg_produtor_rural(false);
      setFlg_contabilista(false);
      setTipo_produtor(0);
      setDisabledSearch(false);
      setFlg_suframa(false);
      setFlg_pauta(false);
      setFlg_aceita_devol_merc(false);
      setFlagInativo(false);
      setFlg_beneficiario(false);
      setNameFornecedor('');
      setMasterDetail(masterDetailDefault);
      setCondicaoAEditar('');
      resetCondicao(CondicaoData);
      setValueCondicao('condicao', { label: 'DIAS DA DATA - DD', value: 2 });
      setValueCondicao('numCondicao', 30);
    },
    [masterDetailDefault, reset, resetCondicao, resetValues, setValueCondicao],
  );

  const changeTipoPessoa = useCallback(
    async (val: number) => {
      /**
       * Muda o tipo de pessoa selecionada
       */
      setTipoPessoa(val);
      setValue('tipoPessoa', val);
      if (isDirty) {
        const continua = await MySwal.fire({
          title: 'O tipo de pessoa foi alterado',
          text: 'Deseja limpar os campos já informados?',
          showCancelButton: true,
          confirmButtonColor: '#8850BF',
          cancelButtonColor: '#DE350B',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        }).then((result) => {
          if (result.isConfirmed) {
            return true;
          }
          return false;
        });
        if (continua) {
          setTab('geral');
          if (val === 0) {
            resetFormData(true);
          } else {
            resetFormData(false);
          }
        } else if (val === 0) {
          setValue('num_cpf', getValues('num_cnpj'));
        } else {
          setValue('num_cnpj', getValues('num_cpf'));
        }
      }
    },
    [getValues, isDirty, setValue, resetFormData],
  );

  const documentoValido = (doc: string): boolean => {
    const isCPF = tipoPessoa === 0;

    const validarDocumento = (numDocumento: string): boolean => {
      if (!numDocumento) {
        toast.warn(`${isCPF ? 'CPF' : 'CNPJ'} informado é inválido`);
        return false;
      }

      const isValid = isCPF
        ? validaCPF(numDocumento)
        : validarCNPJ(numDocumento);

      if (!isValid) {
        toast.warn(`${isCPF ? 'CPF' : 'CNPJ'} informado é inválido`);
      }

      return isValid;
    };

    return isCPF ? validarDocumento(doc) : validarDocumento(doc);
  };

  const onRowClick = useCallback(
    async (param: GridRowParams) => {
      const { row } = param;
      reset();
      const {
        cod_fornecedor,
        cod_pessoa,
        cod_contato,
        cod_endereco,
        tipo_pessoa,
        num_cpf_cnpj,
        num_ie,
        sexo,
        flg_inativo,
        nome_pessoa,
        apelido_fantasia,
        dta_nascimento_abertura,
        num_telefone,
        num_celular,
        des_contato,
        email,
        site,
        num_cep,
        des_logradouro,
        num_endereco,
        des_bairro,
        des_complemento,
        des_cidade,
        des_uf,
        dta_validade_crc,
      } = row;

      setNameFornecedor(nome_pessoa);
      setIsUpdate(true);
      setShowSearch(false);
      const Sexo = Default.sexo.find(
        (tipoSexo) => Number(tipoSexo.value) === Number(sexo),
      );
      setFornecedor(cod_fornecedor);
      setValue('cod_fornecedor', cod_fornecedor);
      setValue('cod_pessoa', cod_pessoa);
      setValue('cod_contato', cod_contato);
      setValue('cod_endereco', cod_endereco);
      setTipoPessoa(tipo_pessoa);
      setValue('tipoPessoa', Number(tipo_pessoa));
      if (tipo_pessoa === 0) {
        setValue('num_cpf', num_cpf_cnpj);
      } else {
        setValue('num_cnpj', num_cpf_cnpj);
      }
      setValue('num_ie', num_ie);
      setValue('sexo', Sexo);
      setValue('inativo', flg_inativo);
      setFlagInativo(flg_inativo);
      setValue('name', nome_pessoa);
      setValue('apelido', apelido_fantasia);
      const validateDate = moment(dta_nascimento_abertura).format('YYYY-MM-DD');
      if (dta_nascimento_abertura !== null) {
        if (validateDate === 'date invalid') {
          const date = dta_nascimento_abertura.split(' ');

          setValue(
            'nascimentoAbertura',
            moment(createDateWithoutTimezone(date[0]), 'DD/MM/YYYY').format(
              'YYYY-MM-DD',
            ),
          );
        } else {
          setValue(
            'nascimentoAbertura',
            moment(createDateWithoutTimezone(dta_nascimento_abertura)).format(
              'YYYY-MM-DD',
            ),
          );
        }
      }
      setValue('telefone', num_telefone);
      setValue('celular', num_celular);
      setValue('contato', des_contato);
      setValue('email', email);
      setValue('site', site);
      setValue('cep', num_cep);
      setValue('logradouro', des_logradouro);
      setValue('numero', num_endereco);
      setValue('bairro', des_bairro);
      setValue('complemento', des_complemento);
      setCidade({
        cidade: des_cidade,
        uf: des_uf,
      });
      setFlg_fabricante(row.flg_fabricante);
      setValue('flg_fabricante', row.flg_fabricante);

      setFlg_exportador(row.flg_exportador);
      setValue('flg_exportador', row.flg_exportador);

      setFlg_regime_st(row.flg_regime_st);
      setValue('flg_regime_st', row.flg_regime_st);

      setFlg_regime_mei(row.flg_regime_mei);
      setValue('flg_regime_mei', row.flg_regime_mei);

      setFlg_substituto_trib(row.flg_substituto_trib);
      setValue('flg_substituto_trib', row.flg_substituto_trib);

      setFlg_optante_simples(row.flg_optante_simples);
      setValue('flg_optante_simples', row.flg_optante_simples);

      setValue(
        'cod_trib_simples',
        tributacoes.find((item) => item.value === row.cod_trib_simples) || {
          label: undefined,
          value: undefined,
        },
      );
      setValue(
        'tipo_frete',
        Fretes.find((item) => item.value === row.tipo_frete) || {
          label: undefined,
          value: undefined,
        },
      );

      setFlg_calc_frete_val_bruto(row.flg_calc_frete_val_bruto);
      setValue('flg_calc_frete_val_bruto', row.flg_calc_frete_val_bruto);

      setFlg_calc_indeniz_val_bruto(row.flg_calc_ideniz_val_bruto);
      setValue('flg_calc_indeniz_val_bruto', row.flg_calc_ideniz_val_bruto);

      setFlg_calc_desp_ac_val_bruto(row.flg_calc_desp_ac_val_bruto);
      setValue('flg_calc_desp_ac_val_bruto', row.flg_calc_desp_ac_val_bruto);

      setFlg_calc_bonif_val_bruto(row.flg_calc_bonif_val_bruto);
      setValue('flg_calc_bonif_val_bruto', row.flg_calc_bonif_val_bruto);

      setFlg_calc_acresc_val_bruto(row.flg_calc_acresc_val_bruto);
      setValue('flg_calc_acresc_val_bruto', row.flg_calc_acresc_val_bruto);

      setFlg_calc_ipi_val_bruto(row.flg_calc_ipi_val_bruto);
      setValue('flg_calc_ipi_val_bruto', row.flg_calc_ipi_val_bruto);

      setFlg_rateia_icms_custo(row.flg_rateia_icms_custo || false);
      setValue('flg_rateia_icms_custo', row.flg_rateia_icms_custo || false);

      setFlg_nao_desc_bc_st(row.flg_nao_desc_bc_st || false);
      setValue('flg_nao_desc_bc_st', row.flg_nao_desc_bc_st || false);

      setFlg_nao_transf_icms(row.flg_nao_transf_icms || false);
      setValue('flg_nao_transf_icms', row.flg_nao_transf_icms || false);

      setFlg_nao_transf_pis_cofins(row.flg_nao_transf_pis_cofins);
      setValue('flg_nao_transf_pis_cofins', row.flg_nao_transf_pis_cofins);

      setFlg_nao_cred_presumido(row.flg_nao_cred_presumido);
      setValue('flg_nao_cred_presumido', row.flg_nao_cred_presumido);

      if (row.flg_produtor_rural !== true) {
        setFlg_produtor_rural(false);
        setValue('flg_produtor_rural', false);
        setTipo_produtor(0);
        setValue('tipo_produtor', 0);
        setValue('produtor_rural_num_cpf', '');
      } else {
        setFlg_produtor_rural(row.flg_produtor_rural);
        setValue('flg_produtor_rural', row.flg_produtor_rural);
        setTipo_produtor(row.tipo_pessoa_produtor || 0);
        setValue('tipo_produtor', row.tipo_pessoa_produtor || 0);
        if (row.tipo_pessoa_produtor === 0) {
          setValue('produtor_rural_num_cpf', row.num_cpf_produtor);
        } else {
          setValue('produtor_rural_num_cnpj', row.num_cpf_produtor);
        }
      }
      if (row.flg_contabilista !== true) {
        setFlg_contabilista(false);
        setValue('num_crc', '');
        setValue('num_seq_crc', '');
        if (dta_validade_crc !== null) {
          const validateDateCRC = moment(
            createDateWithoutTimezone(dta_validade_crc),
          ).format('YYYY-MM-DD');
          if (validateDateCRC === 'date invalid') {
            const date = dta_validade_crc.split(' ');
            setValue(
              'dta_validade_crc',
              moment(createDateWithoutTimezone(date[0]), 'DD/MM/YYYY').format(
                'YYYY-MM-DD',
              ),
            );
          }
        }
      } else {
        setFlg_contabilista(row.flg_contabilista);
        setValue('num_crc', row.num_crc);
        setValue('num_seq_crc', row.num_seq_crc);
        if (dta_validade_crc !== null) {
          setValue(
            'dta_validade_crc',
            moment(createDateWithoutTimezone(dta_validade_crc)).format(
              'YYYY-MM-DD',
            ),
          );
        }
      }

      setDisabledSearch(false);

      setFlg_suframa(row.flg_suframa);

      setValue('flg_suframa', row.flg_suframa);

      setFlg_pauta(row.flg_pauta);
      setValue('flg_pauta', row.flg_pauta);

      setFlg_aceita_devol_merc(row.flg_aceita_devol_merc);
      setValue('flg_aceita_devol_merc', row.flg_aceita_devol_merc);

      setValue('val_minimo_troca', formatCurrencyAsText(row.val_minimo_troca));
      setValue('per_desconto', formatCurrencyAsText(row.per_desconto));
      setValue('per_frete', formatCurrencyAsText(row.per_frete));
      setValue('per_acordo_fin', formatCurrencyAsText(row.per_acordo_fin));
      // Busca condicoes
      setValueCondicao('condicao', { label: 'DIAS DA DATA - DD', value: 2 });
      setValueCondicao('numCondicao', 30);
      setDiasCondicao(30);
      {
        const { data } = await api.get(`/cliente-condicao`, {
          params: {
            cod_pessoa,
            tipo_condicao: 0,
          },
        });
        if (data.success) {
          if (data.message !== 'Nenhum registro encontrado.') {
            const options = data.data.map((cond: any) => {
              const uuid = nanoid();

              return {
                uuid,
                id: uuid,
                dias: `${cond.num_condicao}`,
                condicao: cond.des_condicao,
                finalizadora: cond.des_finalizadora,
                codFinalizadora: cond.cod_finalizadora,
                codCondicao: cond.cod_condicao,
                cod_seq_pessoa_condicao: cond.cod_seq_pessoa_condicao,
              };
            });

            await addUpdate('condicao', options, masterDetail, setMasterDetail);
            setCondicoes(options);
          }
        }
      }
      {
        const { data } = await api.get(`/fornecedor-retencoes`, {
          params: {
            cod_fornecedor,
          },
        });
        if (data.success) {
          if (data.message !== 'Nenhum registro encontrado.') {
            const options = data.data.map(async (retencao: any) => {
              const uuid = nanoid();

              const retencaoObj = {
                uuid,
                id: uuid,
                descricao: retencao.descricao,
                cod_retencao: retencao.cod_retencao,
                conta_reduzida: retencao.conta_reduzida,
                des_conta_contabil: retencao.des_conta_contabil,
              };

              const retencaoDetail: any[] = await insertOrUpdate(
                'retencao',
                retencaoObj,
                masterDetail,
                setMasterDetail,
              );
              setRetencoesRows(retencaoDetail);
            });

            setFlg_beneficiario(true);
          }
        }
      }
    },
    [
      setValue,
      setValueCondicao,
      reset,
      tributacoes,
      masterDetail,
      setMasterDetail,
    ],
  );

  const searchEmpresa = useCallback(async () => {
    setLoadingIcon('buscaPessoa');

    let cnpj = getValues('num_cnpj');
    const isValidCnpj = documentoValido(cnpj);
    if (!isValidCnpj) {
      setLoadingIcon('');
      return;
    }
    cnpj = cnpj.replace(/[^\d]/g, '');

    const res = await api.get(`/fornecedor/buscaFornecedor/${cnpj}`);
    if (res.data.success && res.data.data && res.data.data.length > 0) {
      await MySwal.fire({
        title: 'Fornecedor já cadastrado',
        text: `Já existe um fornecedor cadastrado com documento ${cnpj}. Deseja editar o registro?`,
        showCancelButton: true,
        confirmButtonColor: '#8850BF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.isConfirmed) {
          setLoadingIcon('');
          const row: any = { row: res.data.data[0] };
          onRowClick(row);
        } else {
          setLoadingIcon('');
          return false;
        }
      });
    } else {
      const { data } = await api.get(`/pessoas/num_cpf_cnpj/${cnpj}`);
      if (data.success && data.data && data.data.length > 0) {
        toast.success(`Documento já cadastrado. Dados importados com sucesso.`);
        setLoadingIcon('');
        const sexo = Default.sexo.filter(
          (tipoSexo) => Number(tipoSexo.value) === Number(data.data[0].sexo),
        );
        setValue('cod_pessoa', data.data[0].cod_pessoa);
        setValue('cod_contato', data.data[0].cod_contato);
        setValue('cod_endereco', data.data[0].cod_endereco);
        setValue('num_ie', data.data[0].num_ie);
        setValue('sexo', sexo[0]);
        setValue('name', data.data[0].nome_pessoa);
        setValue('apelido', data.data[0].apelido_fantasia);
        setValue('telefone', data.data[0].num_telefone);
        setValue('celular', data.data[0].num_celular);
        setValue('contato', data.data[0].des_contato);
        setValue('email', data.data[0].email);
        setValue('site', data.data[0].site);
        setValue('cep', data.data[0].num_cep);
        setValue('logradouro', data.data[0].des_logradouro);
        setValue('numero', data.data[0].num_endereco);
        setValue('bairro', data.data[0].des_bairro);
        setValue('complemento', data.data[0].des_complemento);
        setCidade({
          cidade: data.data[0].des_cidade,
          uf: data.data[0].des_sigla,
        });
        setValue(
          'nascimentoAbertura',
          data.data[0].dta_nascimento_abertura
            ? moment(data.data[0].dta_nascimento_abertura)
                .local()
                .format('YYYY-MM-DD')
            : null,
        );
        nascimentoRef.current?.changeStartDate(
          data.data[0].dta_nascimento_abertura,
        );
      } else {
        try {
          const cnpjApi: ICNPJ = await axios.get(
            `https://brasilapi.com.br/api/cnpj/v1/${cnpj}`,
          );

          if (cnpjApi.data) {
            setValue('name', cnpjApi.data.razao_social);
            setValue('apelido', cnpjApi.data.nome_fantasia);
            setValue(
              'telefone',
              cnpjApi.data.ddd_telefone_1 !== ''
                ? cnpjApi.data.ddd_telefone_1.trim()
                : cnpjApi.data.ddd_telefone_1,
            );
            setValue(
              'celular',
              cnpjApi.data.ddd_telefone_1 !== ''
                ? cnpjApi.data.ddd_telefone_1.trim()
                : cnpjApi.data.ddd_telefone_1,
            );
            setValue('cep', cnpjApi.data.cep);
            setValue('logradouro', cnpjApi.data.logradouro);
            setValue('numero', cnpjApi.data.numero);
            setValue('bairro', cnpjApi.data.bairro);
            setValue('complemento', cnpjApi.data.complemento);
            setValue('cidade', cnpjApi.data.municipio);
            setCidade({
              cidade: cnpjApi.data.municipio,
              uf: cnpjApi.data.uf,
            });
            setValue(
              'nascimentoAbertura',
              cnpjApi.data.data_inicio_atividade
                ? moment(cnpjApi.data.data_inicio_atividade)
                    .local()
                    .format('YYYY-MM-DD')
                : null,
            );
            nascimentoRef.current?.changeStartDate(
              cnpjApi.data.data_inicio_atividade,
            );
            setLoadingIcon('');
            return;
          }
        } catch {
          toast.warn(
            'Não foram encontrados dados públicos para o documento informado.',
          );
          setLoadingIcon('');
        }
      }
    }
  }, [getValues, setValue, tipoPessoa, onRowClick, resetFormData]);

  const searchPessoa = useCallback(async () => {
    /**
     * Efetua busca dos dados da pessoa e
     * caso seja cnpj efetua busca com a função searchEmpresa
     */
    setLoadingIcon('buscaPessoa');

    let cpf = getValues('num_cpf');
    const isValid = documentoValido(cpf);
    if (!isValid) {
      setLoadingIcon('');
      return;
    }
    cpf = cpf.replace(/[^\d]/g, '');
    const res = await api.get(`/fornecedor/buscaFornecedor/${cpf}`);
    if (res.data.success && res.data.data && res.data.data.length > 0) {
      await MySwal.fire({
        title: 'Fornecedor já cadastrado',
        text: `Já existe um fornecedor cadastrado com documento ${cpf}. Deseja editar o registro?`,
        showCancelButton: true,
        confirmButtonColor: '#8850BF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.isConfirmed) {
          setLoadingIcon('');
          const row: any = { row: res.data.data[0] };
          onRowClick(row);
        } else {
          setLoadingIcon('');
          return false;
        }
      });
    } else {
      setIsUpdate(false);
      // Caso a pessoa já esteja cadastrada como cliente
      const { data } = await api.get(`/pessoas/num_cpf_cnpj/${cpf}`);
      if (data.success && data.data && data.data.length > 0) {
        toast.success(`Documento já cadastrado. Dados importados com sucesso.`);
        setLoadingIcon('');

        const sexo = Default.sexo.filter(
          (tipoSexo) => Number(tipoSexo.value) === Number(data.data[0].sexo),
        );
        setValue('cod_pessoa', data.data[0].cod_pessoa);
        setValue('cod_contato', data.data[0].cod_contato);
        setValue('cod_endereco', data.data[0].cod_endereco);
        setValue('flg_produtor_rural', data.data[0].flg_produtor_rural);
        setFlg_produtor_rural(data.data[0].flg_produtor_rural);
        setValue('num_ie', data.data[0].num_ie);
        setValue('sexo', sexo[0]);
        setValue('name', data.data[0].nome_pessoa);
        setValue('apelido', data.data[0].apelido_fantasia);
        setValue('telefone', data.data[0].num_telefone);
        setValue('celular', data.data[0].num_celular);
        setValue('contato', data.data[0].des_contato);
        setValue('email', data.data[0].email);
        setValue('site', data.data[0].site);
        setValue('cep', data.data[0].num_cep);
        setValue('logradouro', data.data[0].des_logradouro);
        setValue('numero', data.data[0].num_endereco);
        setValue('bairro', data.data[0].des_bairro);
        setValue('complemento', data.data[0].des_complemento);
        setValue('cidade', data.data[0].des_cidade);
        setCidade({
          cidade: data.data[0].des_cidade,
          uf: data.data[0].des_sigla,
        });
        setValue(
          'nascimentoAbertura',
          data.data[0].dta_nascimento_abertura
            ? moment(data.data[0].dta_nascimento_abertura)
                .local()
                .format('YYYY-MM-DD')
            : null,
        );
        nascimentoRef.current?.changeStartDate(
          data.data[0].dta_nascimento_abertura,
        );
      } else {
        toast.warn(
          'Não foram encontrados dados internos para o documento informado.',
        );
        setLoadingIcon('');
      }
      if (tipoPessoa === 1) {
        searchEmpresa();
      }
    }
  }, [
    getValues,
    setValue,
    tipoPessoa,
    onRowClick,
    resetFormData,
    searchEmpresa,
  ]);

  const handleCursorPosition = (event: any) => {
    const { value, selectionStart, selectionEnd } = event.target;
    const lowerCaseValue = value.toLowerCase();

    const cursorPosition =
      selectionStart === selectionEnd ? selectionStart : selectionEnd;
    const cursorOffset = lowerCaseValue.length - value.length;
    const newCursorPosition = cursorPosition! + cursorOffset;

    return newCursorPosition;
  };

  const openWhatsApp = useCallback(() => {
    let celular = getValues('celular');
    const contato = getValues('contato');

    const regex = new RegExp(
      /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/g,
    );
    const validacao = !regex.test(celular);

    celular = celular.replace(/[^\d]/g, '');

    if (!celular || validacao) {
      toast.warning('Informe um número de celular válido');
      return;
    }

    celular = celular.replace(/[^\d]/g, '');
    if (!contato) {
      window.open(`https://api.whatsapp.com/send?phone=55${celular}`);
      return;
    }

    window.open(
      `https://api.whatsapp.com/send?phone=55${celular}&text=${encodeURI(
        `Olá, ${contato}`,
      )}`,
    );
  }, [getValues]);

  const openSite = useCallback((site) => {
    if (!site) {
      toast.warning('Informe um site válido');
      return;
    }
    window.open(`https://${site}`);
  }, []);

  const searchCep = useCallback(async () => {
    setLoadingIcon('cep');
    let cep = getValues('cep');
    if (!cep) {
      toast.warning('Informe um cep válido');
      setLoadingIcon('');
      return;
    }
    cep = cep.replace(/[^\d]/g, '');
    if (cep === '') {
      toast.warning('Informe um cep válido');
      setLoadingIcon('');
      return;
    }
    try {
      const data: ICEP = await axios.get(
        `https://brasilapi.com.br/api/cep/v2/${cep}`,
      );
      if (data.data && !data.message) {
        setValue(
          'logradouro',
          data.data.street && data.data.street.toUpperCase(),
        );
        setValue(
          'bairro',
          data.data.neighborhood && data.data.neighborhood.toUpperCase(),
        );
        setCidade({
          cidade: data.data.city.toUpperCase(),
          uf: data.data.state,
        });
        setValue('latitude', data.data.location.coordinates.latitude);
        setValue('longitude', data.data.location.coordinates.longitude);
        setLoadingIcon('');
        return;
      }
      if (data.message) {
        toast.warn(data.message);
        setLoadingIcon('');
        return;
      }
    } catch (error: any) {
      setLoadingIcon('');
      toast.warn('CEP não encontrado');
    }
    setLoadingIcon('');
  }, [getValues, setValue]);

  const handleSearchProdutor = useCallback(async () => {
    try {
      setLoadingIcon('Produtor');
      let cpf = getValues('produtor_rural_num_cpf');
      let cnpj = getValues('produtor_rural_num_cnpj');

      if (cpf && tipo_produtor === 0) {
        const validaDoc = validaCPF(cpf);
        if (!validaDoc) {
          setLoadingIcon('');
          return toast.warn(`CPF informado é inválido`);
        }
      }

      if (cnpj && tipo_produtor === 1) {
        const validaDoc = validarCNPJ(cnpj);
        if (!validaDoc) {
          setLoadingIcon('');
          return toast.warn(`CNPJ informado é inválido`);
        }
      }

      cpf = cpf.replace(/[^\d]/g, '');
      cnpj = cnpj.replace(/\D/g, '');

      if ((!cpf && tipo_produtor === 0) || (!cnpj && tipo_produtor === 1)) {
        toast.warning('Informe um documento válido');
        setLoadingIcon('');
        return;
      }

      setLoadingIcon('');
    } catch (error: any) {
      setLoadingIcon('');
    }
  }, [tipo_produtor, getValues, setValue, tipo_produtor]);

  const handleClearCondicao = useCallback(() => {
    setDiasCondicao(30);
    setCondicaoAEditar('');
    setValueCondicao('condicao', { label: 'DIAS DA DATA - DD', value: 2 });
    setValueCondicao('numCondicao', 30);
    setValueCondicao('condicaoFinalizadora', '');
  }, [setValueCondicao]);

  const handleCondicao = handleSubmitCondicao(async (data) => {
    // const condicaoData = data as CondicaoConfirmarProps;
    const uuid =
      condicaoAEditar?.uuid === undefined ? nanoid() : condicaoAEditar?.uuid;

    const exists = condicoes.filter(
      (cond) =>
        cond.codFinalizadora === data.condicaoFinalizadora.value &&
        cond.codCondicao === data.condicao.value &&
        Number(cond.dias) === data.numCondicao &&
        condicaoAEditar.uuid !== cond.uuid,
    );

    if (exists.length > 0)
      return toast.warning('Já existe uma condição com essas informações.');

    const condicaoObj: CondicaoProps = {
      uuid,
      id: uuid,
      cod_seq_pessoa_condicao: condicaoAEditar?.cod_seq_pessoa_condicao,
      dias: data.numCondicao,
      condicao: data.condicao.label,
      finalizadora: data.condicaoFinalizadora.label,
      codFinalizadora: data.condicaoFinalizadora.value,
      codCondicao: data.condicao.value,
    };

    const condicaoDetail: any[] = await insertOrUpdate(
      'condicao',
      condicaoObj,
      masterDetail,
      setMasterDetail,
    );

    setCondicoes(condicaoDetail);
    setValueCondicao('condicao', { label: 'DIAS DA DATA - DD', value: 2 });
    setValueCondicao('numCondicao', 30);
    setValueCondicao('condicaoFinalizadora', '');
  });

  const onDelete = useCallback(async () => {
    try {
      const { data } = await api.delete(`/fornecedor/${fornecedor}`);
      if (data.success && data.message) {
        toast.success('Fornecedor Inativado com sucesso');
        resetFormData();
        setIsUpdate(false);
        setShowSearch(true);
        return;
      }
      toast.warn(data.message);
      setLoader(false);
    } catch (e: any) {
      toast.error(e.data.message);
    }
  }, [fornecedor, resetFormData]);

  const onSubmit = handleSubmit(async (data) => {
    const cidades = getValues('cidades');
    data.doc_automatico = false;
    if (condicoes.length === 0) {
      setLoading(false);
      const confirm = await MySwal.fire({
        title: ``,
        text: 'Condição de pagamento não informada, deseja continuar?',
        showCancelButton: true,
        confirmButtonColor: '#0065FF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(async (result) => {
        return result.isConfirmed;
      });

      if (!confirm) {
        setTab('condicao');
        return confirm;
      }
    }
    if (flg_beneficiario && retencoesRows.length === 0) {
      setLoading(false);
      toast.warning('Retenção deve ser informada');
      setTab('compra');
      return;
    }

    setTab('geral');
    if (data.nascimentoAbertura === 'Data inválida') {
      data.nascimentoAbertura = null;
    }

    if (
      (tipoPessoa === 0 && (data.num_cpf === '' || !data.num_cpf)) ||
      (tipoPessoa === 1 &&
        (data.num_cnpj === '' || !data.num_cnpj) &&
        !isUpdate)
    ) {
      setLoading(false);
      const continua = await MySwal.fire({
        title: 'Confirmação',
        text: `Deseja incluir ${
          tipoPessoa !== 1 ? 'CPF' : 'CNPJ'
        }  gerado internamente?`,
        showCancelButton: true,
        confirmButtonColor: '#8850BF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.isConfirmed) {
          return true;
        }
        return false;
      });
      if (continua) {
        data = {
          ...data,
          doc_automatico: true,
        };
      } else {
        tipoPessoa === 0
          ? setError('num_cpf', {
              type: 'error',
            })
          : setError('num_cnpj', {
              type: 'error',
            });
        return;
      }
    }
    if ((data.num_cnpj || data.num_cpf) && !data.doc_automatico) {
      const isValid =
        tipoPessoa === 0
          ? documentoValido(data.num_cpf)
          : documentoValido(data.num_cnpj);
      if (!isValid) {
        tipoPessoa === 0
          ? setError('num_cpf', {
              type: 'error',
            })
          : setError('num_cnpj', {
              type: 'error',
            });
        return;
      }
    }
    data.num_ie = data.num_ie.trim();
    if (data.num_ie === '' || !data.num_ie) {
      setLoading(false);
      const result = await MySwal.fire({
        title: 'Confirmação',
        text: 'Inscrição Estadual não foi informada. Deseja cadastrá-lo como "ISENTO"',
        showCancelButton: true,
        confirmButtonColor: '#8850BF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      });

      if (!result.isConfirmed) {
        setError('num_ie', {
          type: 'error',
        });
        return;
      }
      data.num_ie = 'ISENTO';
    } else {
      const ie = getValues('num_ie');

      if (!cidade && !cidades.ufValue) {
        return true; // No validation if no state is selected
      }

      if (!validar(ie, cidades.ufValue)) {
        setError('num_ie', {
          type: 'error',
        });
        return;
      }
    }
    try {
      setLoading(true);
      data = {
        ...data,
        des_cidade: data.cidades.label.split(' - ')[0],
        cod_cidade: data.cidades.value,
        des_uf: data.cidades.label.split(' - ')[1],
        per_desconto: transformAsCurrency(data.per_desconto),
        per_frete: transformAsCurrency(data.per_frete),
        per_acordo_fin: transformAsCurrency(data.per_acordo_fin),
      };
      if (isUpdate) {
        // eslint-disable-next-line no-shadow
        const res = await api.put(`pessoafornecedor`, {
          data,
          condicoes,
          masterDetail,
        });
        const { success, message } = res.data;
        if (success) {
          resetFormData(false);
          setIsUpdate(false);
          setShowSearch(true);
          toast.success(message);
        }
      } else {
        // eslint-disable-next-line no-shadow
        const res = await api.post(`pessoafornecedor`, {
          data,
          condicoes,
          masterDetail,
        });
        const { success, message } = res.data;
        if (success) {
          setLoader(true);
          resetFormData(false);
          toast.success(message);
          setShowSearch(false);
        }
        setLoader(false);
      }
    } catch (error: any) {
      setLoader(false);
    }
  });

  const saveCondicao = () => {
    handleCondicao();
    handleClearCondicao();
    setCondicaoAEditar('');
  };

  const saveRetencao = async () => {
    const values = getValues();
    const retencaoFilter: any = retencoes.find(
      (i: any) => i.cod_retencao === values.retencao.value,
    );

    const exists = retencoesRows.find(
      (i) => i.cod_retencao === retencaoFilter.cod_retencao,
    );
    if (exists) {
      toast.warning('Retenção já cadastrada nesse fornecedor');
      return;
    }

    if (retencaoFilter) {
      const uuid = isUpdateRetencoes.isUpdate
        ? isUpdateRetencoes.uuid
        : nanoid();
      const retencaoObj: any = {
        uuid,
        id: uuid,
        descricao: retencaoFilter.descricao,
        cod_retencao: retencaoFilter.cod_retencao,
        conta_reduzida: retencaoFilter.conta_reduzida,
        des_conta_contabil: retencaoFilter.des_conta_contabil,
      };

      const retencaoDetail: any[] = await insertOrUpdate(
        'retencao',
        retencaoObj,
        masterDetail,
        setMasterDetail,
      );
      setRetencoesRows(retencaoDetail);
      handleClearRetencao();
      toast.success('Retenção vinculada com sucesso');
    }
  };

  const deleteRetencao = async (uuid: string) => {
    const retencao: any[] = await deleteItens(
      'retencao',
      uuid,
      masterDetail,
      setMasterDetail,
    );

    setRetencoesRows(retencao);
    handleClearRetencao();
    toast.success('Retenção desvinculada com sucesso');
  };

  const editRetencao = (rowEdit: any) => {
    setIsUpdateRetencoes({
      uuid: rowEdit.uuid,
      isUpdate: true,
    });
    setValue('retencao', {
      value: rowEdit.cod_retencao,
      label: rowEdit.descricao,
    });
  };

  const handleClearRetencao = () => {
    setIsUpdateRetencoes({ uuid: undefined, isUpdate: false });
    setValue('retencao', '');
  };

  return (
    <FornecedorContext.Provider
      value={{
        showSearch,
        setShowSearch,
        resetFormData,

        register,
        handleSubmit,
        control,
        reset,
        setValue,
        getValues,
        setError,
        clearErrors,
        watch,
        formState,

        nameFornecedor,
        setNameFornecedor,
        fornecedor,
        setFornecedor,
        tipoPessoa,
        setTipoPessoa,
        isUpdate,
        setIsUpdate,

        changeTipoPessoa,

        init,
        setInit,
        loadingIcon,
        setLoadingIcon,

        onRowClick,
        searchPessoa,
        searchEmpresa,

        flagInativo,
        setFlagInativo,

        handleCursorPosition,
        openWhatsApp,

        inputSite,
        setinputSite,

        openSite,
        searchCep,

        inputCidadeRef,

        clearCampoCidade,
        setClearCampoCidade,
        cidade,
        cidadeSelected,
        setCidadeSelected,
        flg_fabricante,
        setFlg_fabricante,
        flg_exportador,
        setFlg_exportador,
        flg_regime_st,
        setFlg_regime_st,
        flg_regime_mei,
        setFlg_regime_mei,
        flg_substituto_trib,
        setFlg_substituto_trib,
        flg_optante_simples,
        setFlg_optante_simples,
        tributacoes,
        flg_calc_frete_val_bruto,
        setFlg_calc_frete_val_bruto,
        flg_calc_indeniz_val_bruto,
        setFlg_calc_indeniz_val_bruto,
        flg_calc_desp_ac_val_bruto,
        setFlg_calc_desp_ac_val_bruto,
        flg_calc_bonif_val_bruto,
        setFlg_calc_bonif_val_bruto,
        flg_calc_acresc_val_bruto,
        setFlg_calc_acresc_val_bruto,
        flg_calc_ipi_val_bruto,
        setFlg_calc_ipi_val_bruto,
        flg_rateia_icms_custo,
        setFlg_rateia_icms_custo,
        flg_nao_desc_bc_st,
        setFlg_nao_desc_bc_st,
        flg_nao_transf_icms,
        setFlg_nao_transf_icms,
        flg_nao_transf_pis_cofins,
        setFlg_nao_transf_pis_cofins,
        flg_nao_cred_presumido,
        setFlg_nao_cred_presumido,
        flg_produtor_rural,
        setFlg_produtor_rural,
        tipo_produtor,
        setTipo_produtor,
        setDisabledSearch,

        handleSearchProdutor,

        flg_contabilista,
        setFlg_contabilista,
        flg_suframa,
        setFlg_suframa,
        flg_pauta,
        setFlg_pauta,
        flg_aceita_devol_merc,
        setFlg_aceita_devol_merc,

        condicaoRegister,
        handleSubmitCondicao,
        condicaoControl,
        resetCondicao,
        setValueCondicao,
        formStateCondicao,

        diasCondicao,
        setDiasCondicao,
        initCondicao,
        setInitCondicao,
        finalizadoras,
        condicoesOptions,

        saveCondicao,
        handleClearCondicao,

        condicaoAEditar,
        setCondicaoAEditar,

        condicoes,
        setCondicoes,

        onDelete,

        tab,
        setTab,

        onSubmit,

        loader,

        masterDetail,
        setMasterDetail,

        flg_beneficiario,
        setFlg_beneficiario,
        retencoes,
        retencoesOptions,
        retencoesRows,
        saveRetencao,
        editRetencao,
        deleteRetencao,
      }}
    >
      {children}
    </FornecedorContext.Provider>
  );
};
